import { useEffect } from "react";

const Console = () => {
    useEffect(() => {
        const message = `██╗                                                                    
██║                                                                    
██║                                                                    
██║                                                                    
██║                                                                    
╚═╝                                                                    
                                                                       
███████╗███████╗███████╗                                               
██╔════╝██╔════╝██╔════╝                                               
███████╗█████╗  █████╗                                                 
╚════██║██╔══╝  ██╔══╝                                                 
███████║███████╗███████╗                                               
╚══════╝╚══════╝╚══════╝                                               
                                                                       
██╗   ██╗ ██████╗ ██╗   ██╗                                            
╚██╗ ██╔╝██╔═══██╗██║   ██║                                            
 ╚████╔╝ ██║   ██║██║   ██║                                            
  ╚██╔╝  ██║   ██║██║   ██║                                            
   ██║   ╚██████╔╝╚██████╔╝                                            
   ╚═╝    ╚═════╝  ╚═════╝                                             
                                                                       
███████╗███╗   ██╗███████╗ █████╗ ██╗  ██╗██╗███╗   ██╗ ██████╗        
██╔════╝████╗  ██║██╔════╝██╔══██╗██║ ██╔╝██║████╗  ██║██╔════╝        
███████╗██╔██╗ ██║█████╗  ███████║█████╔╝ ██║██╔██╗ ██║██║  ███╗       
╚════██║██║╚██╗██║██╔══╝  ██╔══██║██╔═██╗ ██║██║╚██╗██║██║   ██║       
███████║██║ ╚████║███████╗██║  ██║██║  ██╗██║██║ ╚████║╚██████╔╝       
╚══════╝╚═╝  ╚═══╝╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝╚═╝╚═╝  ╚═══╝ ╚═════╝        
                                                                       
██╗███╗   ██╗                                                          
██║████╗  ██║                                                          
██║██╔██╗ ██║                                                          
██║██║╚██╗██║                                                          
██║██║ ╚████║                                                          
╚═╝╚═╝  ╚═══╝                                                          
                                                                       
████████╗██╗  ██╗███████╗                                              
╚══██╔══╝██║  ██║██╔════╝                                              
   ██║   ███████║█████╗                                                
   ██║   ██╔══██║██╔══╝                                                
   ██║   ██║  ██║███████╗                                              
   ╚═╝   ╚═╝  ╚═╝╚══════╝                                              
                                                                       
 ██████╗ ██████╗ ███╗   ██╗███████╗ ██████╗ ██╗     ███████╗       ██╗ 
██╔════╝██╔═══██╗████╗  ██║██╔════╝██╔═══██╗██║     ██╔════╝    ██╗╚██╗
██║     ██║   ██║██╔██╗ ██║███████╗██║   ██║██║     █████╗      ╚═╝ ██║
██║     ██║   ██║██║╚██╗██║╚════██║██║   ██║██║     ██╔══╝      ██╗ ██║
╚██████╗╚██████╔╝██║ ╚████║███████║╚██████╔╝███████╗███████╗    ╚═╝██╔╝
 ╚═════╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝ ╚═════╝ ╚══════╝╚══════╝       ╚═╝ 
                                                                       `;
        console.log(message);
    }, []);

    return null;
}

export default Console;